export default function globalAlertBanner(c) {
 
    if( document.querySelector('#btn-global-alert-close') && c.getCookie('alert-cookie') !== 'true' ) {
        document.querySelector('#btn-global-alert-close').addEventListener('click',function(e){
            e.currentTarget.parentElement.parentElement.style.display = 'none';
            document.querySelector('#masthead').classList.remove('global-alert-active');
            
            c.setCookie('alert-cookie', 'true', 1);  
        });
    } else {
        document.querySelector('#btn-global-alert-close').parentElement.parentElement.style.display = 'none';
        document.querySelector('#masthead').classList.remove('global-alert-active');
    }
}