import "/src/css/style.css";

import cookies from "./helpers/cookies.js";

import mainNavigation from "./mainNavigation.js";
import searchModal from "./searchModal";
import dropdownToggle from "./dropdownToggle";
import newCategoryDropdownToggle from "./newCategoryDropdownToggle";
import globalAlertBanner from "./globalAlertBanner"; 

// import categoryDropdownToggle from "./categoryDropdownToggle";

let c = new cookies();

mainNavigation(c);

searchModal();

dropdownToggle();

newCategoryDropdownToggle(); 

globalAlertBanner(c);

window.addEventListener("message", function (event) {
  if (event.data && event.data.source === "UpSalesForm") {
    console.log("Received message from UpSalesForm:", event.data);

    var formId = event.data.formId;
    var content = event.data.content + 70; // Adding 40px to the height
    console.log("Adjusted content (height + 70px):", content);

    // Example: Adjusting the height of the specific iframe
    var iframe = document.getElementById(formId);
    if (iframe) {
      iframe.style.height = content + "px";
      console.log("Adjusted iframe.style.height", iframe.style.height);
    } else {
      console.error("Iframe with ID '" + formId + "' not found");
    }
  } else {
    console.log("Ignored message from other sources");
  }
});

// categoryDropdownToggle();

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
/* if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log("Hot Module Reloaded");
  });
}
 */
